import { config } from "src/config/config";
import { AjaxStatus, Subtitle, AssetMetadata } from "src/service/api_types";
import {
  getAssetsCreatedByUserAPI,
  getAssetsCollectedByUserAPI,
  getAssetsLikedByUserAPI,
  getAssetsRentedByUserAPI,
  getAssetDetailsByUserAPI,
  updateAssetAPI,
  deleteAssetAPI,
  delistAssetAPI,
  copyAssetAPI,
  listAssetAPI,
  offerTradingAssetAPI,
  distributeAssetAPI,
  getAssetsByUploadAPI,
  getAssetMintStatusAPI,
  completeAuctionAPI,
  getAssetsBiddingByUserAPI,
  getFlowTransactionStatusAPI,
} from "src/service/user_asset_api";
import {
  getPreSignedUrlAPI,
  uploadMediaAPI,
  getAssetsStatusAPI,
  deleteUploadAPI,
} from "src/service/media_upload_api";
import { base64FromFile, md5FromFile } from "src/utils/hash_util";
import { mpUploadMediaAPI } from "src/service/multipart_upload";
import { loadStripe } from "@stripe/stripe-js";

export const USER_ASSET_ACTIONS = {
  INIT_ALL_ASSETS_BY_USER: "INIT_ALL_ASSETS_BY_USER",
  START_ALL_ASSETS_BY_USER: "START_ALL_ASSETS_BY_USER",
  GET_ALL_ASSETS_BY_USER: "GET_ALL_ASSETS_BY_USER",
  INIT_ASSETS_CREATED_BY_USER: "INIT_ASSETS_CREATED_BY_USER",
  START_ASSETS_CREATED_BY_USER: "START_ASSETS_CREATED_BY_USER",
  GET_ASSETS_CREATED_BY_USER: "GET_ASSETS_CREATED_BY_USER",
  INIT_ASSETS_COLLECTED_BY_USER: "INIT_ASSETS_COLLECTED_BY_USER",
  START_ASSETS_COLLECTED_BY_USER: "START_ASSETS_COLLECTED_BY_USER",
  GET_ASSETS_COLLECTED_BY_USER: "GET_ASSETS_COLLECTED_BY_USER",
  INIT_KID_ASSETS_COLLECTED_BY_USER: "INIT_KID_ASSETS_COLLECTED_BY_USER",
  START_KID_ASSETS_COLLECTED_BY_USER: "START_KID_ASSETS_COLLECTED_BY_USER",
  GET_KID_ASSETS_COLLECTED_BY_USER: "GET_KID_ASSETS_COLLECTED_BY_USER",
  INIT_ASSETS_RENTED_BY_USER: "INIT_ASSETS_RENTED_BY_USER",
  START_ASSETS_RENTED_BY_USER: "START_ASSETS_RENTED_BY_USER",
  GET_ASSETS_RENTED_BY_USER: "GET_ASSETS_RENTED_BY_USER",
  INIT_KID_ASSETS_RENTED_BY_USER: "INIT_KID_ASSETS_RENTED_BY_USER",
  START_KID_ASSETS_RENTED_BY_USER: "START_KID_ASSETS_RENTED_BY_USER",
  GET_KID_ASSETS_RENTED_BY_USER: "GET_KID_ASSETS_RENTED_BY_USER",
  INIT_ASSETS_LIKED_BY_USER: "INIT_ASSETS_LIKED_BY_USER",
  START_ASSETS_LIKED_BY_USER: "START_ASSETS_LIKED_BY_USER",
  GET_ASSETS_LIKED_BY_USER: "GET_ASSETS_LIKED_BY_USER",
  INIT_ASSETS_BIDDING_BY_USER: "INIT_ASSETS_BIDDING_BY_USER",
  START_ASSETS_BIDDING_BY_USER: "START_ASSETS_BIDDING_BY_USER",
  GET_ASSETS_BIDDING_BY_USER: "GET_ASSETS_BIDDING_BY_USER",
  INIT_GET_ASSETS_BY_UPLOAD: "INIT_GET_ASSETS_BY_UPLOAD",
  START_GET_ASSETS_BY_UPLOAD: "START_GET_ASSETS_BY_UPLOAD",
  GET_ASSETS_BY_UPLOAD: "GET_ASSETS_BY_UPLOAD",
  GET_ASSET_DETALS_BY_USER: "GET_ASSET_DETALS_BY_USER",
  UPDATE_ASSET: "UPDATE_ASSET",
  INIT_UPDATE_ASSET: "INIT_UPDATE_ASSET",
  START_UPDATE_ASSET: "START_UPDATE_ASSET",
  DELETE_ASSET: "DELETE_ASSET",
  DELETE_UPLOAD_ASSET: "DELETE_UPLOAD_ASSET",
  INIT_DELETE_UPLOAD_ASSET: "INIT_DELETE_UPLOAD_ASSET",
  INIT_DELETE_ASSET: "INIT_DELETE_ASSET",
  START_DELETE_ASSET: "START_DELETE_ASSET",
  DELIST_ASSET: "DELIST_ASSET",
  INIT_COPY_ASSET: "INIT_COPY_ASSET",
  START_COPY_ASSET: "START_COPY_ASSET",
  COPY_ASSET: "COPY_ASSET",
  INIT_DELIST_ASSET: "INIT_DELIST_ASSET",
  START_DELIST_ASSET: "START_DELIST_ASSET",
  LIST_ASSET: "LIST_ASSET",
  INIT_LIST_ASSET: "INIT_LIST_ASSET",
  START_LIST_ASSET: "START_LIST_ASSET",
  OFFER_TRADING_ASSET: "OFFER_TRADING_ASSET",
  INIT_OFFER_TRADING_ASSET: "INIT_OFFER_TRADING_ASSET",
  START_OFFER_TRADING_ASSET: "START_OFFER_TRADING_ASSET",
  DISTRIBUTE_ASSET: "DISTRIBUTE_ASSET",
  INIT_DISTRIBUTE_ASSET: "INIT_DISTRIBUTE_ASSET",
  START_DISTRIBUTE_ASSET: "START_DISTRIBUTE_ASSET",
  SALE_ASSETS_SELECTED: "SALE_ASSETS_SELECTED",
  INIT_GET_UPLOADED_ASSETS_STATUS: "INIT_GET_UPLOADED_ASSETS_STATUS",
  START_GET_UPLOADED_ASSETS_STATUS: "START_GET_UPLOADED_ASSETS_STATUS",
  GET_UPLOADED_ASSETS_STATUS: "GET_UPLOADED_ASSETS_STATUS",
  SAVE_ASSET_UPLOAD_PROGRESS: "SAVE_ASSET_UPLOAD_PROGRESS",
  INIT_GET_MINTING_STATUS: "INIT_GET_MINTING_STATUS",
  START_GET_MINTING_STATUS: "START_GET_MINTING_STATUS",
  GET_MINTING_STATUS: "GET_MINTING_STATUS",
  INIT_COMPLETE_AUCTION: "INIT_COMPLETE_AUCTION",
  START_COMPLETE_AUCTION: "START_COMPLETE_AUCTION",
  COMPLETE_AUCTION: "COMPLETE_AUCTION",
  INIT_GET_FLOW_TX_STATUS: "INIT_GET_FLOW_TX_STATUS",
  START_GET_FLOW_TX_STATUS: "START_GET_FLOW_TX_STATUS",
  GET_FLOW_TX_STATUS: "GET_FLOW_TX_STATUS",
};

export const getAllAssetsByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ALL_ASSETS_BY_USER,
  data,
});

export const initAllAssetsByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_ALL_ASSETS_BY_USER,
});

export const startAllAssetsByUser = () => ({
  type: USER_ASSET_ACTIONS.START_ALL_ASSETS_BY_USER,
});

export const getAssetsCreatedByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ASSETS_CREATED_BY_USER,
  data,
});

export const initAssetsCreatedByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_ASSETS_CREATED_BY_USER,
});

export const startAssetsCreatedByUser = () => ({
  type: USER_ASSET_ACTIONS.START_ASSETS_CREATED_BY_USER,
});

export const getAssetsCollectedByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ASSETS_COLLECTED_BY_USER,
  data,
});

export const initAssetsCollectedByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_ASSETS_COLLECTED_BY_USER,
});

export const startAssetsCollectedByUser = () => ({
  type: USER_ASSET_ACTIONS.START_ASSETS_COLLECTED_BY_USER,
});

export const getKidAssetsCollectedByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_KID_ASSETS_COLLECTED_BY_USER,
  data,
});

export const initKidAssetsCollectedByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_KID_ASSETS_COLLECTED_BY_USER,
});

export const startKidAssetsCollectedByUser = () => ({
  type: USER_ASSET_ACTIONS.START_KID_ASSETS_COLLECTED_BY_USER,
});

export const getKidAssetsRentedByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_KID_ASSETS_RENTED_BY_USER,
  data,
});

export const initKidAssetsRentedByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_KID_ASSETS_RENTED_BY_USER,
});

export const startKidAssetsRentedByUser = () => ({
  type: USER_ASSET_ACTIONS.START_KID_ASSETS_RENTED_BY_USER,
});

export const getAssetsRentedByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ASSETS_RENTED_BY_USER,
  data,
});

export const initAssetsRentedByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_ASSETS_RENTED_BY_USER,
});

export const startAssetsRentedByUser = () => ({
  type: USER_ASSET_ACTIONS.START_ASSETS_RENTED_BY_USER,
});

export const getAssetsLikedByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ASSETS_LIKED_BY_USER,
  data,
});

export const initAssetsLikedByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_ASSETS_LIKED_BY_USER,
});

export const startAssetsLikedByUser = () => ({
  type: USER_ASSET_ACTIONS.START_ASSETS_LIKED_BY_USER,
});

export const getAssetsBiddingByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ASSETS_BIDDING_BY_USER,
  data,
});

export const initAssetsBiddingByUser = () => ({
  type: USER_ASSET_ACTIONS.INIT_ASSETS_BIDDING_BY_USER,
});

export const startAssetsBiddingByUser = () => ({
  type: USER_ASSET_ACTIONS.START_ASSETS_BIDDING_BY_USER,
});

export const initGetAssetsByUpload = () => ({
  type: USER_ASSET_ACTIONS.INIT_GET_ASSETS_BY_UPLOAD,
});

export const startGetAssetsByUpload = () => ({
  type: USER_ASSET_ACTIONS.START_GET_ASSETS_BY_UPLOAD,
});

export const getAssetsByUpload = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ASSETS_BY_UPLOAD,
  data,
});

export const getAssetDetailsByUser = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_ASSET_DETALS_BY_USER,
  data,
});

export const updateAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.UPDATE_ASSET,
  data,
});

export const initUpdateAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_UPDATE_ASSET,
});

export const startUpdateAsset = () => ({
  type: USER_ASSET_ACTIONS.START_UPDATE_ASSET,
});

export const deleteAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.DELETE_ASSET,
  data,
});

export const deleteUploadAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.DELETE_UPLOAD_ASSET,
  data,
});

export const initDeleteUploadAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_DELETE_UPLOAD_ASSET,
});

export const initDeleteAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_DELETE_ASSET,
});

export const startDeleteAsset = () => ({
  type: USER_ASSET_ACTIONS.START_DELETE_ASSET,
});

export const delistAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.DELIST_ASSET,
  data,
});

export const initCopyAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_COPY_ASSET,
});

export const startCopyAsset = () => ({
  type: USER_ASSET_ACTIONS.START_COPY_ASSET,
});

export const copyAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.COPY_ASSET,
  data,
});

export const initDistributeAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_DISTRIBUTE_ASSET,
});

export const startDistributeAsset = () => ({
  type: USER_ASSET_ACTIONS.START_DISTRIBUTE_ASSET,
});

export const distributeAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.DISTRIBUTE_ASSET,
  data,
});

export const saleAssetsSelected = (data: any) => ({
  type: USER_ASSET_ACTIONS.SALE_ASSETS_SELECTED,
  data,
});

export const initGetUploadedAssetsStatus = () => ({
  type: USER_ASSET_ACTIONS.INIT_GET_UPLOADED_ASSETS_STATUS,
});

export const startGetUploadedAssetsStatus = () => ({
  type: USER_ASSET_ACTIONS.START_GET_UPLOADED_ASSETS_STATUS,
});

export const getUploadedAssetsStatus = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_UPLOADED_ASSETS_STATUS,
  data,
});

export const initDelistAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_DELIST_ASSET,
});

export const startDelistAsset = () => ({
  type: USER_ASSET_ACTIONS.START_DELIST_ASSET,
});

export const initListAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_LIST_ASSET,
});

export const startListAsset = () => ({
  type: USER_ASSET_ACTIONS.START_LIST_ASSET,
});

export const listAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.LIST_ASSET,
  data,
});

export const initOfferTradingAsset = () => ({
  type: USER_ASSET_ACTIONS.INIT_OFFER_TRADING_ASSET,
});

export const startOfferTradingAsset = () => ({
  type: USER_ASSET_ACTIONS.START_OFFER_TRADING_ASSET,
});

export const offerTradingAsset = (data: any) => ({
  type: USER_ASSET_ACTIONS.OFFER_TRADING_ASSET,
  data,
});

export const saveAssetUploadProgress = (data: any) => ({
  type: USER_ASSET_ACTIONS.SAVE_ASSET_UPLOAD_PROGRESS,
  data,
});

export const initGetAssetMintingStatus = () => ({
  type: USER_ASSET_ACTIONS.INIT_GET_MINTING_STATUS,
});

export const startGetAssetMintingStatus = () => ({
  type: USER_ASSET_ACTIONS.START_GET_MINTING_STATUS,
});

export const getAssetMintingStatus = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_MINTING_STATUS,
  data,
});

export const initCompleteAuction = () => ({
  type: USER_ASSET_ACTIONS.INIT_COMPLETE_AUCTION,
});

export const startCompleteAuction = () => ({
  type: USER_ASSET_ACTIONS.START_COMPLETE_AUCTION,
});

export const completeAuction = (data: any) => ({
  type: USER_ASSET_ACTIONS.COMPLETE_AUCTION,
  data,
});

export const initGetFlowTransactionStatus = (data?: any) => ({
  type: USER_ASSET_ACTIONS.INIT_GET_FLOW_TX_STATUS,
  data,
});

export const startGetFlowTransactionStatus = () => ({
  type: USER_ASSET_ACTIONS.START_GET_FLOW_TX_STATUS,
});

export const getFlowTransactionStatus = (data: any) => ({
  type: USER_ASSET_ACTIONS.GET_FLOW_TX_STATUS,
  data,
});

export const getAssetsCreatedByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    dispatch(startAssetsCreatedByUser());

    return getAssetsCreatedByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets created by user: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAssetsCreatedByUser(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets created by user");
        }
        let assetsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getAssetsCreatedByUser(assetsData));
      });
  };

export const getAssetsCollectedByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
      kid: params.kid,
    };

    if (params.kid) {
      dispatch(startKidAssetsCollectedByUser());
    } else {
      dispatch(startAssetsCollectedByUser());
    }

    return getAssetsCollectedByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets collected by user: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        if (params.kid) {
          dispatch(getKidAssetsCollectedByUser(assetsData));
        } else {
          dispatch(getAssetsCollectedByUser(assetsData));
        }
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets collected by user");
        }
        let assetsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        if (params.kid) {
          dispatch(getKidAssetsCollectedByUser(assetsData));
        } else {
          dispatch(getAssetsCollectedByUser(assetsData));
        }
      });
  };

export const getAssetsRentedByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    if (params.kid) {
      dispatch(startKidAssetsRentedByUser());
    } else {
      dispatch(startAssetsRentedByUser());
    }

    return getAssetsRentedByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets rented by user: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        if (params.kid) {
          dispatch(getKidAssetsRentedByUser(assetsData));
        } else {
          dispatch(getAssetsRentedByUser(assetsData));
        }
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets rented by user");
        }
        let assetsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        if (params.kid) {
          dispatch(getKidAssetsRentedByUser(assetsData));
        } else {
          dispatch(getAssetsRentedByUser(assetsData));
        }
      });
  };

export const getAssetsLikedByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    dispatch(startAssetsLikedByUser());

    return getAssetsLikedByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets liked by user: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAssetsLikedByUser(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets liked by user");
        }
        let assetsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getAssetsLikedByUser(assetsData));
      });
  };

export const getAssetsBiddingByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    dispatch(startAssetsBiddingByUser());

    return getAssetsBiddingByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets bidding by user: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAssetsBiddingByUser(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets bidding by user");
        }
        let assetsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getAssetsBiddingByUser(assetsData));
      });
  };

export const getAssetsByUploadDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      transactionId: params.transactionId,
      listed: params.listed,
    };

    dispatch(startGetAssetsByUpload());

    return getAssetsByUploadAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets by upload: ", data);
        }
        let assetsData = {
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAssetsByUpload(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets by upload");
        }
        let assetsData = {
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        };
        dispatch(getAssetsByUpload(assetsData));
      });
  };

export const getAssetDetailsByUserDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      assetSerialID: params.assetSerialID,
    };

    return getAssetDetailsByUserAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received asset details by user: ", data);
        }
        dispatch(
          getAssetDetailsByUser({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting asset details by user");
        }
        dispatch(
          getAssetDetailsByUser({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const updateAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    assetTokenID: params.assetTokenID,
    title: params.title,
    description: params.description,
    brand_id: params.brand_id,
    group_key: params.group_key,
    assetType: params.assetType,
    batch: params.batch,
    creatorRoyaltyRate: params.creatorRoyaltyRate,
    rentalCreatorRoyaltyRate: params.rentalCreatorRoyaltyRate,
    saleStartingAt: params.saleStartingAt,
    saleEndingAt: params.saleEndingAt,
    secondarySaleEnabled: params.secondarySaleEnabled,
    tradeEnabled: params.tradeEnabled,
    secondaryRentalEnabled: params.secondaryRentalEnabled,
    minSellablePrice: params.minSellablePrice,
    downloadEnabled: params.downloadEnabled,
  };

  dispatch(startUpdateAsset());

  return updateAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Updated asset: ", data);
      }
      dispatch(
        updateAsset({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in updating asset");
      }
      dispatch(
        updateAsset({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const deleteAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    assetTokenID: params.assetTokenID,
    typeOfToken: params.typeOfToken,
  };

  dispatch(startDeleteAsset());

  return deleteAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Deleted asset: ", data);
      }
      dispatch(
        deleteAsset({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in deleting asset");
      }
      dispatch(
        deleteAsset({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const deleteUploadAssetDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      orh_account_id: params.orh_account_id,
      transaction_id: params.transaction_id,
    };

    //dispatch(startDeleteAsset());

    return deleteUploadAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Deleted asset: ", data);
        }
        dispatch(
          deleteUploadAsset({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in deleting asset");
        }
        dispatch(
          deleteUploadAsset({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const delistAssetDispatch = (params: any) => (dispatch: Function) => {
  const options = {
    accessToken: params.accessToken,
    assets: params.assets,
    batch: params.batch,
  };

  dispatch(startDelistAsset());

  return delistAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Delisted asset: ", data);
      }
      dispatch(
        delistAsset({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in delisting asset");
      }
      dispatch(
        delistAsset({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const copyAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    mvpVersion: params.mvpVersion,
    accessToken: params.accessToken,
    copies_count: params.copies_count,
    title: params.title,
    description: params.description,
    kid: params.kid,
    asset_type: params.asset_type,
  };

  dispatch(startCopyAsset());

  return copyAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Copied asset: ", data);
      }
      dispatch(
        copyAsset({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in Copying asset");
      }
      dispatch(
        copyAsset({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const listAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    mvpVersion: params.mvpVersion,
    accessToken: params.accessToken,
    assetTokenIDs: params.assetTokenIDs,
    salePrice: params.salePrice,
    rentPrice: params.rentPrice,
    rentalPeriod: params.rentalPeriod,
    batch: params.batch,
    auctionPrice: params.auctionPrice,
    auctionStart: params.auctionStart,
    auctionPeriod: params.auctionPeriod,
    minBidIncrement: params.minBidIncrement,
  };

  dispatch(startListAsset());

  return listAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Listed asset: ", data);
      }
      dispatch(
        listAsset({
          ajaxStat: AjaxStatus.DONE,
          response: data,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in listing asset");
      }
      dispatch(
        listAsset({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};

export const offerTradingAssetDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      submittedAssetTokenIDs: params.submittedAssetTokenIDs,
      requestedAssetTokenIDs: params.requestedAssetTokenIDs,
      waitOffers: params.waitOffers,
    };

    dispatch(startOfferTradingAsset());

    return offerTradingAssetAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("offer Trading asset: ", data);
        }
        dispatch(
          offerTradingAsset({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in offer trading asset");
        }
        dispatch(
          offerTradingAsset({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const distributeAssetDispatch =
  (params: any) => (dispatch: Function) => {
    const options = {
      accessToken: params.accessToken,
      assetTokenIDs: params.assetTokenIDs,
      limitOnNumber: params.limitOnNumber,
      acquirers: params.acquirers,
      freeDistributionFee: params.freeDistributionFee,
      lang: params.lang,
    };

    let stripePromise;
    const getStripe = (publishable_key) => {
      if (!stripePromise) {
        stripePromise = loadStripe(publishable_key);
      }

      return stripePromise;
    };

    const redirectToCheckout = async (id, publishable_key) => {
      console.log("redirectToCheckout");
      const stripe = await getStripe(publishable_key);
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      console.log("Stripe checkout error", error);
    };

    dispatch(startDistributeAsset());

    return distributeAssetAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("distributeAssetAPI asset: ", data);
        }
        dispatch(
          distributeAsset({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
        if (options.freeDistributionFee !== 0) {
          redirectToCheckout(data.id, data.key);
        }
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in distributeAssetAPI");
        }
        dispatch(
          distributeAsset({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getAssetsStatusDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      userId: params.userId,
      includeMint: params.includeMint,
    };

    dispatch(startGetUploadedAssetsStatus());

    return getAssetsStatusAPI(options)
      .done((data, status, xhr) => {
        dispatch(
          getUploadedAssetsStatus({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets status");
        }
        dispatch(
          getUploadedAssetsStatus({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

const parseSubtitles = async (subtitles: any[]): Promise<Subtitle[]> => {
  return await Promise.all(
    subtitles.map(async (sub): Promise<Subtitle> => {
      return {
        language: sub.language,
        b64_data: await base64FromFile(sub.file.originFileObj),
      };
    })
  );
};

export const uploadVideoDispatch =
  (params: any) => async (dispatch: Function) => {
    let metadata: AssetMetadata = {};
    let options: any = {
      accessToken: params.accessToken,
      asset_file_name: params.asset_file_name,
      poster_file_name: params.poster_file_name,
      trailer_file_name: params.trailer_file_name,
      title: params.title,
      description: params.description,
      assetType: params.assetType,
      totalCopies: params.totalCopies,
      userId: params.userId,
      assetUploadType: params.assetUploadType,
      largeAssetUpload: params.largeAssetUpload,
      metadata: JSON.stringify(metadata),
      startSerialNumber: params.startSerialNumber,
      mediaMetadata: params.mediaMetadata,
    };
    if (params.assetFile) {
      options["assetMd5"] = await md5FromFile(params.assetFile);
    }
    if (params.posterFile) {
      options["posterMd5"] = await md5FromFile(params.posterFile);
    }
    if (params.previewFile) {
      options["trailerMd5"] = await md5FromFile(params.previewFile);
    }
    if (params.assetSubtitles) {
      options["assetSubtitles"] = await parseSubtitles(params.assetSubtitles);
    }
    if (params.trailerSubtitles) {
      options["trailerSubtitles"] = await parseSubtitles(
        params.trailerSubtitles
      );
    }

    return getPreSignedUrlAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Get pre-signed url: ", data);
        }

        const onUploadProgress = (percent: number) => {
          if (config.APP_DEBUG) {
            console.log("Upload progress: ", percent.toFixed(2));
          }

          let uuid = data.uuid;
          if (params.uploadProgress) {
            params.uploadProgress(uuid, percent);
          }
          dispatch(
            saveAssetUploadProgress({
              uuid: uuid,
              progress: percent,
            })
          );
        };

        if (params.assetFile) {
          let assetOptions = {
            uuid: data.uuid,
            headers: data.asset_pre_signed_url.headers,
            pre_signed_url: data.asset_pre_signed_url.url,
            asset_upload_type: params.assetUploadType,
            orh_account_id: params.userId,
            file: params.assetFile,
            md5: options.assetMd5,
            content_md5: options.assetMD5,
            upload_id: data.asset_pre_signed_url.upload_id,
            uploadProgress: onUploadProgress,
          };

          if (params.largeAssetUpload) {
            mpUploadMediaAPI(assetOptions);
          } else {
            uploadMediaAPI(assetOptions)
              .done((data, status, xhr) => {
                if (config.APP_DEBUG) {
                  console.log("Headers: ", xhr.getAllResponseHeaders());
                  console.log("Uploaded media file: ", data);
                }
              })
              .fail((err) => {
                if (config.APP_DEBUG) {
                  console.error("Error in uploading media file");
                }
              });
          }
        }

        if (params.posterFile) {
          let posterOptions = {
            uuid: data.uuid,
            headers: data.poster_pre_signed_url.headers,
            pre_signed_url: data.poster_pre_signed_url.url,
            asset_upload_type: "POSTER",
            orh_account_id: params.userId,
            file: params.posterFile,
            md5: options.assetMd5,
            content_md5: options.posterMD5,
            upload_id: data.poster_pre_signed_url.upload_id,
          };
          if (params.largeAssetUpload) {
            mpUploadMediaAPI(posterOptions);
          } else {
            uploadMediaAPI(posterOptions)
              .done((data, status, xhr) => {
                if (config.APP_DEBUG) {
                  console.log("Headers: ", xhr.getAllResponseHeaders());
                  console.log("Uploaded media file: ", data);
                }
              })
              .fail((err) => {
                if (config.APP_DEBUG) {
                  console.error("Error in uploading media file");
                }
              });
          }
        }

        if (params.previewFile) {
          let trailerOptions = {
            uuid: data.uuid,
            headers: data.trailer_pre_signed_url.headers,
            pre_signed_url: data.trailer_pre_signed_url.url,
            asset_upload_type: "TRAILER",
            orh_account_id: params.userId,
            file: params.previewFile,
            md5: options.assetMd5,
            content_md5: options.trailerMD5,
            upload_id: data.trailer_pre_signed_url.upload_id,
          };
          if (params.largeAssetUpload) {
            mpUploadMediaAPI(trailerOptions);
          } else {
            uploadMediaAPI(trailerOptions)
              .done((data, status, xhr) => {
                if (config.APP_DEBUG) {
                  console.log("Headers: ", xhr.getAllResponseHeaders());
                  console.log("Uploaded media file: ", data);
                }
              })
              .fail((err) => {
                if (config.APP_DEBUG) {
                  console.error("Error in uploading media file");
                }
              });
          }
        }
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting pre-signed url");
        }
      });
  };

export const getAssetMintStatusDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      transactionId: params.transactionId,
    };

    dispatch(startGetAssetMintingStatus());

    return getAssetMintStatusAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Got asset minting status: ", data);
        }
        dispatch(
          getAssetMintingStatus({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting asset minting status");
        }
        dispatch(
          getAssetMintingStatus({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const completeAuctionDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      assetTokenID: params.assetTokenID,
    };

    dispatch(startCompleteAuction());

    return completeAuctionAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Got complete auction status: ", data);
        }
        dispatch(
          completeAuction({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in completing auction");
        }
        dispatch(
          completeAuction({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getFlowTransactionStatusDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      transactionId: params.transactionId,
    };

    dispatch(startGetFlowTransactionStatus());

    return getFlowTransactionStatusAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Got Flow transaction status: ", data);
        }
        dispatch(
          getFlowTransactionStatus({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting Flow transaction status");
        }
        dispatch(
          getFlowTransactionStatus({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };
