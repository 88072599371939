import { merge } from "lodash";
import { config } from "src/config/config";
import { USER_ASSET_ACTIONS } from "src/actions/user_asset_actions";
import {
  AjaxStatus,
  APICallStatus,
  TrmFlowTxStatus,
} from "src/service/api_types";
import { checkApiStatus } from "src/service/utils";
import { jsonToMap, mapToJson } from "src/utils/map_util";
import { data } from "jquery";

const defaultState = {
  allAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  createdAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  collectedAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  collectedKidAssets: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  rentedAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  favoriteAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  biddingAssets: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  assetsByUpload: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  currentAsset: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  updateAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
  delistAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
  listAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
  copyAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
  distributeAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
  selectedSaleAssets: {
    data: [],
  },
  deleteUploadAsset: {
    status: { status: APICallStatus.INIT },
  },
  deleteAsset: {
    status: { status: APICallStatus.INIT },
    transaction: null,
  },
  assetsStatus: {
    pageNumber: 0,
    status: { status: APICallStatus.INIT },
    data: null,
  },
  mintStatus: {
    status: { status: APICallStatus.INIT },
    data: null,
  },
  completeAuction: {
    status: { status: APICallStatus.INIT },
  },
  assetsUploadProgress: "",
  flowTxStatus: {
    status: { status: APICallStatus.INIT },
    data: { status: TrmFlowTxStatus.UNKNOWN },
  },
};

export const userAssetsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_ASSET_ACTIONS.GET_ALL_ASSETS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.GET_ALL_ASSETS_BY_USER: ", action.data);
      }

      let assets = action.data.response;
      return {
        ...state,
        allAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.allAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_ALL_ASSETS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_ALL_ASSETS_BY_USER: ",
          action.data
        );
      }

      let newState = {
        allAssets: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_ALL_ASSETS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_ALL_ASSETS_BY_USER: ",
          action.data
        );
      }

      let newState = {
        allAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_ASSETS_CREATED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_ASSETS_CREATED_BY_USER: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        createdAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.createdAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_ASSETS_CREATED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_ASSETS_CREATED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        createdAssets: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_ASSETS_CREATED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_ASSETS_CREATED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        createdAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_ASSETS_COLLECTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_ASSETS_COLLECTED_BY_USER: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        collectedAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.collectedAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_ASSETS_COLLECTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_ASSETS_COLLECTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        collectedAssets: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_ASSETS_COLLECTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_ASSETS_COLLECTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        collectedAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_KID_ASSETS_COLLECTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_KID_ASSETS_COLLECTED_BY_USER: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        collectedKidAssets: {
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_KID_ASSETS_COLLECTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_KID_ASSETS_COLLECTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        collectedKidAssets: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_KID_ASSETS_COLLECTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_KID_ASSETS_COLLECTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        collectedKidAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_ASSETS_RENTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_ASSETS_RENTED_BY_USER: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        rentedAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.rentedAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_ASSETS_RENTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_ASSETS_RENTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        rentedAssets: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_ASSETS_RENTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_ASSETS_RENTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        rentedAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_KID_ASSETS_RENTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_KID_ASSETS_RENTED_BY_USER: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        rentedKidAssets: {
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_KID_ASSETS_RENTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_KID_ASSETS_RENTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        rentedKidAssets: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_KID_ASSETS_RENTED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_KID_ASSETS_RENTED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        rentedKidAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_ASSETS_LIKED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_ASSETS_LIKED_BY_USER: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        favoriteAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.favoriteAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_ASSETS_LIKED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_ASSETS_LIKED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        favoriteAssets: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_ASSETS_LIKED_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_ASSETS_LIKED_BY_USER: ",
          action.data
        );
      }

      let newState = {
        favoriteAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_ASSETS_BIDDING_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_ASSETS_BIDDING_BY_USER: ",
          action.data
        );
      }

      let assets = action.data.response;
      return {
        ...state,
        biddingAssets: {
          pageNumber:
            assets && assets.length
              ? action.data.pageNumber
              : state.biddingAssets.pageNumber,
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.INIT_ASSETS_BIDDING_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_ASSETS_BIDDING_BY_USER: ",
          action.data
        );
      }

      let newState = {
        biddingAssets: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_ASSETS_BIDDING_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_ASSETS_BIDDING_BY_USER: ",
          action.data
        );
      }

      let newState = {
        biddingAssets: {
          status: { status: APICallStatus.IN_PROGRESS },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_GET_ASSETS_BY_UPLOAD: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_GET_ASSETS_BY_UPLOAD: ",
          action.data
        );
      }

      let newState = {
        assetsByUpload: {
          status: { status: APICallStatus.INIT },
          data: [],
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_GET_ASSETS_BY_UPLOAD: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_GET_ASSETS_BY_UPLOAD: ",
          action.data
        );
      }

      let newState = {
        assetsByUpload: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_ASSETS_BY_UPLOAD: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.GET_ASSETS_BY_UPLOAD: ", action.data);
      }

      let assets = action.data.response;
      return {
        ...state,
        assetsByUpload: {
          data: assets,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.GET_ASSET_DETALS_BY_USER: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.GET_ASSET_DETALS_BY_USER: ",
          action.data
        );
      }

      let status = action.data.response.status;
      let asset = action.data.response.data;
      let newState = {
        currentAsset: {
          data: status === "ok" ? asset : null,
          status: checkApiStatus(action.data.ajaxStat, status),
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.UPDATE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.UPDATE_ASSET: ", action.data);
      }

      let newState = {
        updateAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_UPDATE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.INIT_UPDATE_ASSET: ", action.data);
      }

      let newState = {
        updateAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_UPDATE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.START_UPDATE_ASSET: ", action.data);
      }

      let newState = {
        updateAsset: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.DELETE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.DELETE_ASSET: ", action.data);
      }

      let newState = {
        deleteAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.DELETE_UPLOAD_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.DELETE_UPLOAD_ASSET: ", action.data);
      }

      let newState = {
        deleteUploadAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_DELETE_UPLOAD_ASSET: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_DELETE_UPLOAD_ASSET: ",
          action.data
        );
      }

      let newState = {
        deleteUploadAsset: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_DELETE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.INIT_DELETE_ASSET: ", action.data);
      }

      let newState = {
        deleteAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_DELETE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.START_DELETE_ASSET: ", action.data);
      }

      let newState = {
        deleteAsset: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.DELIST_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.DELIST_ASSET: ", action.data);
      }

      let newState = {
        delistAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_DELIST_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.INIT_DELIST_ASSET: ", action.data);
      }

      let newState = {
        delistAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_DELIST_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.START_DELIST_ASSET: ", action.data);
      }

      let newState = {
        delistAsset: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.LIST_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.LIST_ASSET: ", action.data);
      }

      let newState = {
        listAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_LIST_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.INIT_LIST_ASSET: ", action.data);
      }

      let newState = {
        listAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_LIST_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.START_LIST_ASSET: ", action.data);
      }

      let newState = {
        listAsset: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.COPY_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.COPY_ASSET: ", action.data);
      }

      let newState = {
        copyAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.DISTRIBUTE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.DISTRIBUTE_ASSET: ", action.data);
      }

      let newState = {
        distributeAsset: {
          status: checkApiStatus(action.data.ajaxStat, null),
          transaction: action.data.response,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_DISTRIBUTE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.INIT_DISTRIBUTE_ASSET: ", action.data);
      }

      let newState = {
        distributeAsset: {
          status: { status: APICallStatus.INIT },
          transaction: null,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_DISTRIBUTE_ASSET: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.START_DISTRIBUTE_ASSET: ", action.data);
      }

      let newState = {
        distributeAsset: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.SALE_ASSETS_SELECTED: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.SALE_ASSETS_SELECTED: ", action.data);
      }

      return {
        ...state,
        selectedSaleAssets: action.data,
      };
    }
    case USER_ASSET_ACTIONS.INIT_GET_UPLOADED_ASSETS_STATUS: {
      let newState = {
        assetsStatus: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_GET_UPLOADED_ASSETS_STATUS: {
      let newState = {
        assetsStatus: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_UPLOADED_ASSETS_STATUS: {
      return {
        ...state,
        assetsStatus: {
          data: action.data.response,
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
    }
    case USER_ASSET_ACTIONS.SAVE_ASSET_UPLOAD_PROGRESS: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.SAVE_ASSET_UPLOAD_PROGRESS: ",
          action.data
        );
      }

      const uuid = action.data.uuid;
      const assetId = `ast_${uuid}`;
      const progress = action.data.progress;
      const assetMap = jsonToMap(state.assetsUploadProgress);
      assetMap.set(assetId, progress);

      return {
        ...state,
        assetsUploadProgress: mapToJson(assetMap),
      };
    }
    case USER_ASSET_ACTIONS.INIT_GET_MINTING_STATUS: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_GET_MINTING_STATUS: ",
          action.data
        );
      }

      let newState = {
        mintStatus: {
          status: { status: APICallStatus.INIT },
          data: null,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_GET_MINTING_STATUS: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_GET_MINTING_STATUS: ",
          action.data
        );
      }

      let newState = {
        mintStatus: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_MINTING_STATUS: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.GET_MINTING_STATUS: ", action.data);
      }

      let mintStat = action.data.response;
      let newState = {
        mintStatus: {
          status: checkApiStatus(action.data.ajaxStat, null),
          data: mintStat,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_COMPLETE_AUCTION: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.INIT_COMPLETE_AUCTION: ", action.data);
      }

      let newState = {
        completeAuction: {
          status: { status: APICallStatus.INIT },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_COMPLETE_AUCTION: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.START_COMPLETE_AUCTION: ", action.data);
      }

      let newState = {
        completeAuction: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.COMPLETE_AUCTION: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.COMPLETE_AUCTION: ", action.data);
      }

      let newState = {
        completeAuction: {
          status: checkApiStatus(action.data.ajaxStat, null),
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.INIT_GET_FLOW_TX_STATUS: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.INIT_GET_FLOW_TX_STATUS: ",
          action.data
        );
      }

      let reset_status =
        action.data !== undefined ? action.data.reset_status : false;
      let statData = state.flowTxStatus.data;
      if (statData) {
        if (
          reset_status ||
          statData.status === TrmFlowTxStatus.FAILED ||
          statData.status === TrmFlowTxStatus.DONE
        ) {
          statData.status = TrmFlowTxStatus.UNKNOWN;
        }
      }

      let newState = {
        flowTxStatus: {
          status: { status: APICallStatus.INIT },
          data: statData,
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.START_GET_FLOW_TX_STATUS: {
      if (config.APP_DEBUG) {
        console.log(
          "USER_ASSET_ACTIONS.START_GET_FLOW_TX_STATUS: ",
          action.data
        );
      }

      let newState = {
        flowTxStatus: {
          status: { status: APICallStatus.IN_PROGRESS },
        },
      };
      return merge({}, state, newState);
    }
    case USER_ASSET_ACTIONS.GET_FLOW_TX_STATUS: {
      if (config.APP_DEBUG) {
        console.log("USER_ASSET_ACTIONS.GET_FLOW_TX_STATUS: ", action.data);
      }

      let txStat = action.data.response;
      if (action.data.ajaxStat === AjaxStatus.FAILED) {
        txStat.status = TrmFlowTxStatus.FAILED;
      }

      let newState = {
        flowTxStatus: {
          status: checkApiStatus(action.data.ajaxStat, null),
          data: txStat,
        },
      };
      return merge({}, state, newState);
    }
    default:
      return state;
  }
};
