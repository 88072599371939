import {
  AssetMediaType,
  AssetBusinessCond,
  AssetStatus,
} from "src/service/api_types";
import * as Bowser from "bowser";
import json5 from "json5";
import { config } from "src/config/config";

export const parseMediaTypeFromFile = (file: File) => {
  if (file.type.startsWith("image/")) {
    return AssetMediaType.IMAGE;
  } else if (file.type.startsWith("video/")) {
    return AssetMediaType.VIDEO;
  } else if (file.type.startsWith("audio/")) {
    return AssetMediaType.VIDEO;
  } else {
    return null;
  }
};

export const parseExtension = (url: string) => {
  if (!url) return null;
  let extStart = url.indexOf(".", url.lastIndexOf("/") + 1);
  if (extStart === -1) return null;

  let ext = url.substr(extStart + 1);
  let extEnd = ext.search(/$|[?#]/);

  return ext.substring(0, extEnd);
};

export const parseMediaTypeFromUrl = (url: string) => {
  const imgExts = ["png", "jpg", "jpeg"];
  const vidExts = ["mp4", "mov", "m4f", "m4v"];
  const audExts = ["mp3", "aac", "m4a"];

  let ext = parseExtension(url);
  if (!ext) {
    return AssetMediaType.UNKNOWN;
  }

  if (imgExts.includes(ext)) {
    return AssetMediaType.IMAGE;
  } else if (vidExts.includes(ext)) {
    return AssetMediaType.VIDEO;
  } else if (audExts.includes(ext)) {
    return AssetMediaType.VIDEO;
  } else {
    return AssetMediaType.UNKNOWN;
  }
};

const SUPPORTED_SUBTITLE_EXTENSIONS = ["vtt"];
const SUPPORTED_SUBTITLE_MIME_TYPES = ["text/vtt"];
const SUPPORTED_VIDEO_MIME_TYPES = [
  "video/mp4",
  "video/x-m4v",
  "video/quicktime",
];

export const getSubtitleMimeTypes = () => {
  return SUPPORTED_SUBTITLE_MIME_TYPES;
};

export const getVideoMimeTypes = () => {
  return SUPPORTED_VIDEO_MIME_TYPES;
};

export const isSupportedVideoFile = (file: File) => {
  return file ? getVideoMimeTypes().includes(file.type) : false;
};

export const isSupportedSubtitleFile = (file: File) => {
  return file ? getSubtitleMimeTypes().includes(file.type) : false;
};

export const supportSale = (asset: any) => {
  // TODO: asset.type_of_listing削除の際にここも削除対応する
  if (asset) {
    if (
      asset.type_of_listing === AssetBusinessCond.SALE ||
      asset.sale_listing_copies_count !== null
    ) {
      return true;
    }
  }
  return false;
};

export const supportRent = (asset: any) => {
  if (asset && asset.type_of_listing) {
    if (asset.type_of_listing === AssetBusinessCond.RENT) {
      return true;
    }
  }
  return false;
};

export const supportAuction = (asset: any) => {
  if (asset && asset.type_of_listing) {
    if (asset.type_of_listing === AssetBusinessCond.AUCTION) {
      return true;
    }
  }
  return false;
};

// 引数のウォレットアドレス（owner_address）のユーザーが、
// 引数のコンテンツ（assetKid）の所有しているシリアルを出品できるかを返す。
export const supportList = (assetKid: any, owner_address: any) => {
  return (
    assetKid.asset_kid_setting?.secondary_sale_enabled ||
    assetKid.asset_kid_setting?.secondary_rental_enabled ||
    assetKid.asset_kid_setting?.trade_enabled ||
    assetKid.creator_account_id === owner_address
  );
};

export const hasAssetsInProcessing = (assets: any[]) => {
  for (let asset of assets) {
    if (asset.status === AssetStatus.PROCESSINIG) {
      return true;
    }
  }
  return false;
};

export const parsePreviewUrl = (asset: any) => {
  let preview: string = "";

  const parser = Bowser.getParser(window.navigator.userAgent);
  let browser = parser.getBrowser();

  if (asset.media_type === AssetMediaType.IMAGE) {
    preview = asset.poster_url;
  } else {
    if (asset.preview_url && asset.preview_url !== "None") {
      if (
        asset.preview_url.startsWith("http://") ||
        asset.preview_url.startsWith("https://")
      ) {
        preview = asset.preview_url;
      } else {
        let urls = json5.parse(asset.preview_url);
        if (browser.name?.startsWith("Safari")) {
          preview = urls.trailer_url_manifest_hls;
        } else {
          preview = urls.trailer_url_manifest_dash;
        }
      }
    } else {
      preview = asset.poster_url;
    }
  }

  return preview;
};

export const mapAssetMediaType = (type: string) => {
  let mediaTypes = {
    image: "ASSET_IMAGE",
    audio: "ASSET_AUDIO",
    video: "ASSET_VIDEO",
  };

  return mediaTypes[type];
};
